<template>
  <router-view></router-view>
</template>

<script>
export default {
  name: "App",
  components: {},
  setup() {
    
  },
};
</script>

<style>
#app {
  overflow: hidden;
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  margin-top: 63px;
  font-size: 1rem;
}
.firebase-emulator-warning {
  display: none;
}
body{
  overflow-y: hidden;
}
</style>
